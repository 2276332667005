.line {
    width: 50%;
    height: 2px;
    background-color: black;
    transition: transform 1ms ease-in-out;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */

}

.BottomLine {
    display: flex;
    flex-direction: row;
}
