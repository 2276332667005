.wavy-line-container {
    height: 50px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.wavy-line {
    position: absolute;
    top: 15px;
    width: 100%;
    height: 20px;
    background: radial-gradient(16px, transparent, transparent 4px, black 4px, black 10px, transparent 11px);
    background-size: 30px 40px;
    animation: wave 20s linear infinite;
}

.wavy-line2 {
    top: 32px;
    left: 15px;
    background-position: 0px -20px;
    animation: wave 20s linear infinite;
}

@keyframes wave {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 1000px;
    }
}

@keyframes wave2 {
    0% {
        background-position-x: 0px;
    }
    100% {
        background-position-x: -20px;
    }
}
