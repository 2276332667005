.HeaderSection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    min-height: 80vh;
    min-width: 75vw;
    margin-bottom: 40vh;
}

.Header {
    padding: 10px 0;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    min-height: 80vh;
    min-width: 75vw;
    border-top: solid;
    border-left: solid;
    border-right: solid;
}


.Header h1 {
    font-size: xxx-large;
    font-family: inherit;
    margin: 0;
    opacity: 1;
    transition: opacity .33s;

}

.Header h1:hover {
    margin: 0;
    opacity: 70%;

}

.TagLine {
    min-width: 75vw;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1%;
}

.TagLine .h3 {
    font-size: xxx-large;
    font-family: inherit;
    opacity: 10%;
}
