.project {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    min-height: 100vh;
    min-width: 100vw;
}
