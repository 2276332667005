
.sticky-nav {
    position: absolute;
    left: 25%;
    top: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    justify-content: center;
    width: 50%;
    height: 7%;
    z-index: 150;
    background: linear-gradient(0.25turn, rgba(252, 242, 216, 0.1), rgba(252, 242, 216, 0.0), rgba(252, 242, 216, 0.1));
    transition: background-color .5s;
    border-radius: 5px;
}

.sticky-nav button {

    font-family: inherit;
    color: #1f1e1c;
    overflow: hidden;
    cursor: default;
    align-self: normal;
    font-size: 18px;
    font-weight: initial;
    opacity: 1;
    transition: font-weight 1s;
    width: 70px;
    z-index: 200;
}

.sticky-nav:hover {
    opacity: 100%;
}


.sticky-nav button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: rgb(255, 235, 195);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}

.sticky-nav button::after,
.sticky-nav button:focus::after {
    opacity: 1;
    transform: translate(0.2em, 0);
}


.sticky-nav button {
    font-family: inherit;
    color: #1f1e1c;
    overflow: hidden;
    cursor: default;
}

.sticky-nav button::after {
    opacity: 1;
    transform: translate(-100%, 0);
}

.sticky-nav button:hover::after,
.sticky-nav button:focus::after {
    transform: translate(0, 0);
}

.sticky-nav button {
    font-family: inherit;
    color: #1f1e1c;
    overflow: hidden;
    cursor: pointer;
    display: block;
    position: relative;
}

.sticky-nav.fixed {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    flex-wrap: nowrap;
    gap: 4px;
    transform: none;
    background: linear-gradient(0.25turn, rgba(252, 242, 216, 0.1), rgba(252, 242, 216, 0.5), rgba(252, 242, 216, 0.1));
    transition: background-color 2s;
}
